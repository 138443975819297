import { FC, useState, useEffect } from "react";
import ModalCollectFeedback from "components/Modals/ModalCollectFeedback";
import ModalCollectInstructions from "components/Modals/ModalCollectInstructions";
import Modal from "utils/hookedComponents/Modal";
import { useModal } from "utils/hookedComponents/Modal/core/modal.hook";
import {
  formatRemainingTime,
  handleCTAClick,
  canClaimTheReward,
  getClaimRewardAvailableDate,
  checkCollectExpiration,
} from "./core/collect.helpers";
import { useCollect } from "./core/collect.hook";
import { Trans, useTranslation } from "react-i18next";
import Button from "components/Inputs/Button";
import Layout from "components/Layout";
import dayjs, { Dayjs } from "dayjs";
import { IContext } from "./core/collect.types";
import { Context } from "./core/collect.context";
import { useDispatch, useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { useParams, useSearchParams } from "react-router-dom";
import { deviceIsMobile } from "utils";
import Desktop from "pages/Desktop";
import ModalCollectError from "components/Modals/ModalCollectError";
import i18n from "utils/internationalization";
import { ErrorsType } from "components/Modals/ModalCollectError/core/modalCollectError.types";
import { useColor } from "utils/hooks/useColor";
import { useInternationalization } from "utils/hooks/useInternationalization";
import { useCurrency } from "utils/hooks/useCurrency";

export { Context as CollectContext } from "./core/collect.context";

export type { IContext as ICollectContext } from "./core/collect.types";

const Collect: FC = () => {
  const [alreadyClaimed, setAlreadyClaimed] = useState(null);
  const reward = useSelector((state: IConfigurationState) => state.reward);

  const dispatch = useDispatch();
  const [activationDate, setActivationDate] = useState<Dayjs | null>(null);
  const claimLimitTime = 300;
  const [remainingTime, setRemainingTime] = useState<number>(claimLimitTime);
  const [
    isModalCollectInstructionsVisible,
    showModalCollectInstructions,
    hideModalCollectInstruction,
    referenceCollectInstructions,
  ] = useModal();
  const [
    isModalCollectFeedbackVisible,
    showModalCollectFeedback,
    ,
    referenceCollectFeedback,
  ] = useModal();
  const { t } = useTranslation("collect");
  const [searchParams] = useSearchParams();
  const rewardId = searchParams.get("rewardId");
  const token = searchParams.get("token");
  const { entityId } = useParams();
  const isMobile = deviceIsMobile();

  useCollect({
    showModalCollectInstructions,
    setRemainingTime,
    setActivationDate,
    activationDate,
    rewardId,
    reward,
    dispatch,
    token,
    claimLimitTime,
    alreadyClaimed,
    setAlreadyClaimed,
  });

  const context: IContext = {
    rewardId,
    companyId: entityId,
  };

  useColor({ primaryColor: reward?.pColor });

  useInternationalization({
    i18n,
    locale: reward?.lg && reward?.lg[0] ? reward?.lg[0] : "fr",
    contents: null,
  });

  useEffect(() => {
    if (
      alreadyClaimed === null &&
      dayjs().diff(dayjs(reward?.ra), "second") > claimLimitTime
    ) {
      setAlreadyClaimed(true);
    }
  }, [alreadyClaimed, remainingTime, reward]);

  let minimumAmount = reward?.pc?.minimumAmount;

  const { currency: amountWithCurrency } = useCurrency({
    locale: reward?.lg?.[0],
    country: reward?.cy,
    amount: minimumAmount,
  });

  console.log("reward from collect modal", reward);
  console.log(
    "can claim",
    reward?.cf,
    canClaimTheReward(reward?.cf || reward?.wa, reward?.ia)
  );
  console.log(
    "date to show",
    getClaimRewardAvailableDate(reward?.cf || reward?.wa, !!reward?.cf)
  );

  return (
    <Context.Provider value={context}>
      {reward?.isFetched &&
        (isMobile ? (
          <Layout hideFooter className="collect">
            {reward && (
              <>
                <div className="collect__ticket bg-white mt-3 pl-3 pr-3 f f-direction-column f-jc-space-b bg-sparkles">
                  <div className="collect__ticket-top f f-direction-column f-center">
                    <div
                      className="collect__img"
                      style={{ backgroundImage: `url(${reward?.pup})` }}
                    />
                    {reward?.n && (
                      <div className="f f-direction-column">
                        <Trans
                          ns="collect"
                          i18nKey={
                            minimumAmount
                              ? "prize.withAmount"
                              : reward?.pc?.eligibility
                              ? "prize"
                              : "prize.withoutAmount"
                          }
                          components={[
                            <br />,
                            <b />,
                            <div className="c-primary" />,
                            <div className="c-primary h1 ft-cap-first" />,
                          ]}
                          values={{
                            reward: reward?.n,
                            restaurant: reward?.cn || reward?.dn,
                            amountWithCurrency:
                              amountWithCurrency ||
                              Number(minimumAmount).toFixed(2),
                          }}
                        />
                        {reward?.pc?.eligibility && (
                          <div>{reward?.pc?.eligibility}</div>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    className={`collect__ticket-bottom f f-direction-column f-center ${
                      remainingTime === claimLimitTime
                        ? "collect__ticket-bottom--hidden"
                        : ""
                    }`}
                  >
                    <p className="f f-direction-column">
                      <Trans
                        ns="collect"
                        i18nKey="activation"
                        components={[<span className="fw-700"></span>]}
                        values={{
                          date: reward?.ra
                            ? dayjs(reward.ra).format("DD/MM/YYYY")
                            : null,
                          hour: reward?.ra
                            ? dayjs(reward.ra).format("HH[h]mm")
                            : null,
                        }}
                      />
                    </p>
                    <p className="f f-direction-column">
                      <Trans
                        ns="collect"
                        i18nKey="validity"
                        components={[
                          <span
                            className={`h1 ft-up collect__remaining-time ${
                              remainingTime < 0
                                ? "collect__remaining-time--expired"
                                : ""
                            }`}
                          ></span>,
                        ]}
                        values={{
                          time: formatRemainingTime(remainingTime),
                        }}
                      />
                    </p>
                  </div>
                </div>
                <Button
                  text={t("CTA")}
                  onClick={handleCTAClick(showModalCollectFeedback)}
                  className="mb-2 mt-3"
                />
                <Modal
                  reference={referenceCollectInstructions}
                  isVisible={
                    isModalCollectInstructionsVisible ||
                    reward.hasError ||
                    (reward.ra && isModalCollectInstructionsVisible) ||
                    alreadyClaimed
                  }
                  content={
                    reward.hasError ? (
                      <ModalCollectError errorType={ErrorsType.NOT_FOUND} />
                    ) : alreadyClaimed ? (
                      <ModalCollectError
                        errorType={ErrorsType.ALREADY_CLAIMED}
                      />
                    ) : !reward.cc ? (
                      <ModalCollectError
                        claimAvailableDate={getClaimRewardAvailableDate(
                          reward?.cf || reward?.wa,
                          !!reward?.cf
                        )}
                        errorType={
                          checkCollectExpiration(reward?.wa, reward?.cf)
                            ? ErrorsType.EXPIRED
                            : ErrorsType.NOT_AVAILABLE
                        }
                      />
                    ) : canClaimTheReward(
                        reward?.cf || reward?.wa,
                        reward.ia
                      ) ? (
                      <ModalCollectInstructions
                        hideModal={hideModalCollectInstruction}
                      />
                    ) : (
                      <ModalCollectError
                        claimAvailableDate={getClaimRewardAvailableDate(
                          reward?.cf || reward?.wa,
                          !!reward?.cf
                        )}
                        errorType={ErrorsType.NOT_AVAILABLE}
                      />
                    )
                  }
                />
                <Modal
                  reference={referenceCollectFeedback}
                  isVisible={isModalCollectFeedbackVisible}
                  content={<ModalCollectFeedback />}
                />
              </>
            )}
          </Layout>
        ) : (
          <Desktop isCollect />
        ))}
    </Context.Provider>
  );
};

export default Collect;
