import Button from "components/Inputs/Button";
import Layout from "components/Layout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { Icons } from "utils/constants/icons";
import InfoField from "./InfoField";
import ConsentField from "./ConsentField";
import {
  ActionTypes,
  AppThunkDispatch,
} from "redux/configuration/configuration.types";
import { useNavigate, useParams } from "react-router";
import { endpoints } from "config/endpoints";
import axios from "services/axios.service";
import { useColor } from "utils/hooks/useColor";
import { deviceIsMobile, isDev } from "utils";
import Desktop from "pages/Desktop";
import StoreSelect from "./StoreSelect";
import { useTranslation } from "react-i18next";
import { setCanPlayAgain } from "redux/game/game.actions";

export default function InformationForm() {
  const dispatch: AppThunkDispatch = useDispatch();
  const navigate = useNavigate();
  const { entityId } = useParams();
  const isMobile = deviceIsMobile();
  const { t } = useTranslation("informationForm");
  const { t: t2 } = useTranslation("game");

  const { configuration, fromFilled, skipFromStartup } = useSelector(
    (state: IConfigurationState) => {
      return state;
    }
  );

  useEffect(() => {
    if (fromFilled) {
      navigate(`/${entityId}`);
    }
  }, [navigate, fromFilled, configuration, entityId]);

  const leadSettings = useMemo(
    () => configuration?.s?.leadSettings,
    [configuration]
  );
  const [showForm, setShowForm] = useState(skipFromStartup);
  const storeList = useMemo(() => configuration?.ets, [configuration]);
  const [idToUse, setIdToUse] = useState<string>();

  const [fieldData, setFieldData] = useState<Record<string, string>>({});
  const [fieldError, setFieldError] = useState<Record<string, string>>({});
  const [consentData, setConsentData] = useState<Record<string, boolean>>({});
  const [error, setError] = useState<{
    id: string;
    field: string;
    code: string;
  }>();

  const handleFieldChange = useCallback((fieldId: string, value: string) => {
    setFieldData((prev) => ({ ...prev, [fieldId]: value }));
  }, []);

  const handleConsent = useCallback((id: string) => {
    setConsentData((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);

  const disabled = useMemo(() => {
    const requiredFields = leadSettings?.fields?.filter(
      (field) => field.required
    );
    const requiredConsents = leadSettings?.consents?.filter(
      (consent) => consent.required
    );
    if (!requiredFields || !requiredConsents) {
      return false;
    }

    return (
      requiredFields.some((field) => !fieldData[field.id]) ||
      requiredConsents.some((consent) => !consentData[consent.id]) ||
      !!fieldError.email
    );
  }, [fieldData, consentData, leadSettings, fieldError]);

  const handleSubmit = useCallback(async () => {
    try {
      let { data } = await axios.api.post(
        `${endpoints.api.formUpdate as any}`,
        {
          cid: entityId,
          fields: fieldData,
          consents: consentData,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Submit", data);
      dispatch({
        type: ActionTypes.SET_FORM_FILLED,
      });
      dispatch(setCanPlayAgain(entityId));
      navigate(`/${entityId}`);
    } catch (error) {
      console.log("Error", error?.response?.data?.error);
      setError(error?.response?.data?.error);

      if (isDev()) {
        // added due to API not working on local environments
        dispatch({
          type: ActionTypes.SET_FORM_FILLED,
        });
        navigate(`/${entityId}`);
      }
    }
  }, [fieldData, consentData, dispatch, entityId, navigate]);

  useColor({ primaryColor: configuration?.s?.pColor });

  console.log("fieldData", fieldData, "consentData", consentData);
  console.log("leadSettings", configuration, leadSettings);

  useEffect(() => {
    if (!configuration) {
      navigate(`/${entityId}`);
    }
  }, [configuration, entityId, navigate, leadSettings]);

  useEffect(() => {
    if (fieldData.email) {
      //check if email is valid
      const { email } = fieldData;
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regex.test(email)) {
        setFieldError((prev) => ({ ...prev, email: "INVALID_FORMAT" }));
      } else {
        setFieldError((prev) => ({ ...prev, email: "" }));
      }
    }
  }, [fieldData]);

  const handleStartButton = useCallback(() => {
    if (storeList) {
      dispatch({
        type: ActionTypes.SET_SKIP_FROM_STARTUP,
      });
      idToUse && navigate(`/${idToUse}/`);
    } else setShowForm(true);
  }, [idToUse, navigate, storeList, dispatch]);

  if (!isMobile) return <Desktop />;

  if (!showForm) {
    return (
      <Layout className={`game`}>
        <div
          className={`collect__img bg-[var(--primary)] overflow-hidden p-0 flex items-center justify-center mb-10
          ${configuration?.s?.hl}
          `}
        >
          {configuration?.s?.hl && (
            <img
              src={configuration?.s?.hl}
              alt="logo"
              className="object-cover  "
            />
          )}
        </div>
        <p className="text-3xl font-extrabold uppercase mb-1">
          {configuration?.s?.t || "De nombreux cadeaux à gagner."}
        </p>
        <p className="text-xl font-extrabold uppercase mb-1">
          {configuration?.s?.st ||
            "Lancez la machine et tentez de remporter l'un des nombreux lots."}
        </p>
        {storeList ? (
          <StoreSelect storeList={storeList} setSelectedStore={setIdToUse} />
        ) : (
          <p className="text-xl font-extrabold uppercase mb-5 c-primary-dark">
            {configuration?.dn}
          </p>
        )}

        <Button
          appearance="primary"
          text={t2("CTA.participate")}
          onClick={handleStartButton}
          icon={Icons.chevronRight}
          disabled={storeList ? !idToUse : false}
          useDisableDark
        />
      </Layout>
    );
  }

  return (
    <Layout backgroundImage={configuration?.s?.bkg} className="   ">
      <div className="flex flex-col items-center justify-center gap-8  py-10">
        <div>
          <section className=" mb-3.5">
            <p className="text-3xl font-extrabold  mb-3.5">{t("title")}</p>
            <p>{t("subtitle")}</p>
          </section>
          {/* information */}
          <section className="w-full  relative p-6 rounded-md z-10">
            <div className="absolute inset-0 bg-white rounded-xl opacity-90 -z-10"></div>

            <section className="flex flex-col gap-3.5  w-full">
              <p className="font-bold text-xl w-full text-left">
                {t("identity")}
              </p>
              {leadSettings?.fields
                ?.filter((v) => v.section === "identity")
                .map((leadField) => (
                  <InfoField
                    value={fieldData[leadField.id]}
                    key={leadField.id}
                    leadFiled={leadField}
                    setFieldValue={handleFieldChange}
                  />
                ))}
            </section>
            <section className="flex flex-col gap-3.5 w-full mt-6">
              <p className="font-bold text-xl w-full text-left">
                {t("contact")}
              </p>
              {leadSettings?.fields
                ?.filter((v) => v.section === "contact")
                .map((leadField) => (
                  <InfoField
                    value={fieldData[leadField.id]}
                    error={
                      fieldError[leadField.id] &&
                      t(`error.${fieldError[leadField.id]}`)
                    }
                    key={leadField.id}
                    leadFiled={leadField}
                    setFieldValue={handleFieldChange}
                  />
                ))}
            </section>
            {/* consents */}
            <section className="flex flex-col items-start w-full gap-3.5 mt-6">
              {leadSettings?.consents?.map((leadConsent) => (
                <ConsentField
                  checked={consentData[leadConsent.id] as boolean}
                  key={leadConsent.id}
                  leadConsent={leadConsent}
                  handleConsent={handleConsent}
                />
              ))}
            </section>
          </section>
        </div>
        {error && (
          <section>
            <p className="text-red-500 text-sm">{t(`error.${error.code}`)}</p>
          </section>
        )}
        <Button
          useDisableDark
          disabled={disabled}
          appearance="primary"
          text={t2("CTA")}
          onClick={handleSubmit}
          className=" w-full  "
        />
        <button
          className=" c-primary-dark font-semibold"
          onClick={() => setShowForm(false)}
        >
          {t2("cancel")}
        </button>
      </div>
    </Layout>
  );
}
