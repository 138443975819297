import Button from "components/Inputs/Button";
import { MouseEventHandler, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getBrowserName, getMobileOperatingSystem } from "utils";

type Props = {
  handleClick: MouseEventHandler;
};

export default function ModalHomeInfo(props: Props) {
  const { handleClick } = props;
  const { userAgent } = navigator;
  const { t } = useTranslation("modals", { keyPrefix: "info" });

  const [debug, setDebug] = useState(false);

  // find if android or ios
  const os = useMemo(
    () => (getMobileOperatingSystem() === "iOS" ? "apple" : "android"),
    []
  );
  const browser = useMemo(() => {
    const val = getBrowserName();
    if (val === "safari" && os === "android") return "chrome";
    return val;
  }, [os]);

  if (debug) {
    return (
      <div onClick={() => setDebug(false)}>
        <p>OS: {os}</p>
        <p>Browser: {browser}</p>
        <div>{userAgent}</div>
        {/* @ts-ignore */}
        <div>Has opera function: {!!navigator.opera}</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <img
        // onClick={() => setDebug(true)}
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
        alt="return instructions"
        src={`/static/images/browser/${os}/${browser}.png`}
      />
      <h2>
        <Trans
          ns="modals"
          i18nKey="info.title"
          components={[<span className="c-google"></span>]}
        />
      </h2>
      <div style={{ paddingTop: "20px" }}>{t("description")}</div>

      <Button
        appearance="google"
        style={{ width: "100%", padding: "1.3rem" }}
        text={t("CTA")}
        className="modal__btn"
        onClick={handleClick}
      />
    </div>
  );
}
